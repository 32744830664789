import { Component, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowService } from '../../../services/workflow.service';
import {
  ConnectorModel, DiagramComponent, NodeModel, LayoutType, NodeConstraints,
  SnapConstraints, ScrollSettingsModel, Diagram, Rect, LineRouting, DiagramConstraints, LayoutModel, DataBinding, ComplexHierarchicalTree, LineDistribution, DecoratorModel,
  ShapeStyleModel, ConnectionPointOrigin, ConnectorConstraints, AnnotationConstraints, LayoutAnimation,
} from '@syncfusion/ej2-angular-diagrams';
import { ContextMenuComponent, MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

Diagram.Inject(DataBinding, ComplexHierarchicalTree, LineDistribution, LayoutAnimation);

@Component({
  selector: 'app-workflow-organism',
  templateUrl: './workflow-organism.component.html',
  styleUrls: ['./workflow-organism.component.scss']
})
export class WorkflowOrganismComponent {
  @ViewChild('diagram', { static: false }) diagram: DiagramComponent;
  @ViewChild('cmenu', { static: false }) contextMenu: ContextMenuComponent;
  @ViewChild('confirmationDialog') confirmationDialog: DialogComponent;
  @ViewChild('workflowNameInput') workflowNameInput!: ElementRef;
  workflowList: any[] = [];
  isCreated: boolean = false;
  schedule: boolean = false;
  nodes: NodeModel[] = [];
  connectors: ConnectorModel[] = [];
  icons = { Entity: 'category', Step: 'account_tree', Condition: 'call_split', Action: 'donut_small', Evaluation: 'task_alt' };
  cards = [
    {
      id: 1,
      type: 'Entity',
      icon: 'category',
      title: 'Entity',
      subtitle: 'Create an entity'
    },
    {
      id: 2,
      type: 'Step',
      // icon: 'donut_small',
      icon: 'account_tree',
      title: 'Step',
      subtitle: 'Create workflow steps'
    },
    {
      id: 3,
      type: 'Condition',
      icon: 'call_split',
      title: 'Condition',
      subtitle: 'Define conditional paths'
    },
    {
      id: 4,
      type: 'Action',
      icon: 'donut_small',
      title: 'Action',
      subtitle: 'Define an action on entity'
    },
    {
      id: 5,
      type: 'Evaluation',
      icon: 'task_alt',
      title: 'Evaluation',
      subtitle: 'Evaluate Conditions'
    }
  ];
  evaluationNode: NodeModel | undefined;
  currCard: any;
  done: any = [];
  selectedNodeId: string;
  isFromStepsMenu: boolean = false;
  selectedStepNode: any = null;
  stepWithEval: any = [];
  showModal: boolean;
  isEditingName: boolean = false;
  adjustedWidth: number = 110;
  currentDialogData: any;
  workflowCodeControl = new FormControl('', Validators.required);
  workflowNameControl = new FormControl('', Validators.required);
  initializationComplete$ = new BehaviorSubject<boolean>(false);
  menuItems: MenuItemModel[] = [
    { text: 'Configure', id: 'configure' },
    { text: 'Delete', id: 'delete' }
  ];
  snapSettings: Object = {
    constraints: SnapConstraints.None,
  }
  scrollSettings?: ScrollSettingsModel = {
    scrollLimit: 'Infinity',
    canAutoScroll: true,
    scrollableArea: new Rect(0, 0, 500, 500), horizontalOffset: 0
  }
  layout: LayoutModel = {
    type: 'ComplexHierarchicalTree',
    connectionPointOrigin: ConnectionPointOrigin.DifferentPoint,
    horizontalSpacing: 120,
    verticalSpacing: 70,
    orientation: 'LeftToRight',
    margin: { left: 20, right: 20, top: 20, bottom: 20 },
    enableRouting: true,
    enableAnimation: true,
  };
  public nodeDefaults(obj) {
    if (obj.id === 'Start') {
      obj.width = 20;
      obj.height = 23;
    } else {
      const label = obj?.properties?.annotations[0]?.properties?.content;
      const labelWidth = label?.length * 10; // Approximate width per character
      const calculatedWidth = Math.max(140, labelWidth + 60);
      let width = calculatedWidth - 30;
      obj.width = width || 120;
      obj.height = 60;
    }
    obj.constraints = NodeConstraints.Default | NodeConstraints.AllowDrop;

    return obj;
  }
  public getConnectorDefaults(connector: ConnectorModel, diagram: Diagram): ConnectorModel {
    connector.style = {
      strokeColor: '#6BA5D7',
      strokeWidth: 2
    };
    (((connector as ConnectorModel).targetDecorator as DecoratorModel).style as ShapeStyleModel).fill = '#6BA5D7';
    (((connector as ConnectorModel).targetDecorator as DecoratorModel).style as ShapeStyleModel).strokeColor = '#6BA5D7';
    connector.type = 'Orthogonal';
    connector.cornerRadius = 7;
    connector.targetDecorator.height = 7;
    connector.targetDecorator.width = 7;
    return connector;
  }
  workflowData = {
    code: '',
    name: '',
    entity: '',
    preFilterConditions: null,
    steps: [],
    active: false,
    cronExpression: '',
    diagramState: null
  }
  diagramNodeLabels: any = { Entity: 'Entity', Step: 'Step', Condition: 'Condition', Action: 'Action', };
  isLoading = true;
  isEdited: boolean = false;
  selectedTab: string = 'minutes';
  cronExpression: string = '';
  tabs = [
    { value: 'minutes', label: 'Minutes' },
    { value: 'hourly', label: 'Hourly' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'yearly', label: 'Yearly' },
    { value: 'advanced', label: 'Advanced' }
  ];
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  minutesModel = { minutes: 1 };
  hourlyModel = { hours: 1 };
  dailyModel = { time: '00:00', days: 1 };
  weeklyModel = { time: '00:00', days: ['MON'] };
  monthlyModel = { time: '00:00', day: 1, months: 1 };
  yearlyModel = { time: '00:00', day: 1, month: 1 };
  advancedModel = { expression: '* * * * *' };
  constructor(private workflowService: WorkflowService, private snack: MatSnackBar, private fb: FormBuilder) {
    this.workflowNameControl.valueChanges.subscribe(value => {
      this.workflowNameControl.setValue(value, { emitEvent: false });
    });
  }

  ngOnInit() {
    this.getWorkflowList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workflowData && this.workflowData) {
      this.workflowData = changes.workflowData.currentValue;
    }
  }

  updateNodeLabels(obj: any) {
    this.diagramNodeLabels[obj.key] = obj.value;
    const node = this.diagram.getObject(this.selectedNodeId) as NodeModel;
    if (node) {
      node.annotations[0].content = obj.value;
      const nodeIcon = this.cards.find((ele: any) => this.selectedNodeId.includes(ele.title))?.icon;
      const { html, width } = this.getNodeHTML(obj.value, nodeIcon, this.selectedNodeId);
      node.shape = { type: 'HTML', content: html };
      node.width = width - 30;
      this.diagram?.dataBind();
      this.diagram?.doLayout();
    }

  }

  created(): void {
    this.initializeDiagram();
  }

  initializeDiagram() {
    this.initializationComplete$.next(false);
    if (this.diagram) {
      this.diagram.clear(); // Clear all existing nodes and connectors
      this.nodes = [];
      this.diagram.layout = this.layout;
      // this.nodes = this.nodes || [];

      const startNode: NodeModel = {
        id: 'Start',
        offsetX: 40,
        offsetY: 200,
        shape: { type: 'HTML', content: this.getStartNodeHTML("Start") },
      };
      this.nodes.push(startNode);
      this.diagram.add(startNode);
      this.diagram.doLayout();
      this.initializationComplete$.next(true);
    }
  }

  getNodeHTML(label: string, icon: string, id: string): { html: string, width: number } {
    const baseWidth = 140; // Minimum width for the node
    const labelWidth = label?.length * 10; // Approximate width per character
    const calculatedWidth = Math.max(baseWidth, labelWidth + 60);

    const htmlContent = `
        <div id="${id}" style="background-color: #fff; padding: 5px; border-radius: 5px; width: ${calculatedWidth}px; height: 60px; box-shadow: 0px 2px 4px -1px #342813, 0px 4px 5px 0px #ffffff, 0px 1px 10px 0px #ffffff; display: flex; align-items: center; justify-content: space-between;" draggable=true>
            <i class="material-icons" style="font-size: 25px; margin-left: 10px;">${icon}</i>
            <h3 style="margin: 0; text-align: center;">${label}</h3>
            <div id="${id}-menu" class="icon-cont" style="cursor: pointer; display: flex; align-self: flex-start;" [matMenuTriggerFor]="menu" #menuTrigger>
                <i class="material-icons" style="font-size: 18px;">more_vert</i>
            </div>
        </div>
    `;

    return { html: htmlContent, width: calculatedWidth };
  }

  getStartNodeHTML(label: string): string {
    return `
    <div>
      <div style="width: 24px;height: 24px;background-color: black;border-radius: 20px;display: flex;
        align-items: center;justify-content: center;">
        <div style="width: 8px;height: 8px;background-color: white;border-radius: 20px;display: flex;
          align-items: center;justify-content: center;">
        </div>
      </div>
      <p style="margin-top: 5px;padding: 0px;font-size:12px">${label}</p>
    </div>
    `;
  }

  addNode(step: { type: string; title: string, icon: string }) {
    if (step.type === 'Entity' && this.nodes.find((node: any) => node.id.startsWith('Entity'))) {
      this.showSnackbar('Only one Entity node is allowed');
      return;
    }
    if (this.nodes.length === 1 && step.type !== 'Entity') {
      this.showSnackbar('Add an Entity node before proceeding with any other actions');
      return;
    }
    if (this.nodes.length === 2 && step.type !== 'Step') {
      this.showSnackbar('Add a Step node before proceeding with any other actions');
      return;
    }

    let newNode: NodeModel | undefined;

    if (step.type === 'Condition') {
      newNode = {
        id: `${step.type}-${this.diagram.nodes.length}`,
        shape: { type: 'HTML', content: this.getNodeHTML(step.title, step.icon, `${step.type}-${this.diagram.nodes.length}`).html },
        annotations: [{ content: step.title }],
        constraints: NodeConstraints.Default | NodeConstraints.Drag | NodeConstraints.AllowDrop,
      };

    } else if (step.type === 'Action') {
      newNode = {
        id: `${step.type}-${this.diagram.nodes.length}`,
        shape: { type: 'HTML', content: this.getNodeHTML(step.title, step.icon, `${step.type}-${this.diagram.nodes.length}`).html },
        annotations: [{ content: step.title }],
        constraints: NodeConstraints.Default | NodeConstraints.Drag | NodeConstraints.AllowDrop
      };
    } else if (step.type === 'Evaluation') {

      const stepNodes = this.diagram.nodes.filter((ele: any) => ele.id.startsWith('Step'));
      const stepId = this.selectedStepNode?.id ? this.selectedStepNode?.id : stepNodes[stepNodes.length - 1].id;
      const conditionNodes = this.nodes.filter(node =>
        node.id.startsWith('Condition') &&
        this.diagram.connectors.some(connector => connector.sourceID === stepId && connector.targetID === node.id)
      );
      const existingEvaluationNode = this.nodes.find(node =>
        node.id.startsWith('Evaluation') &&
        conditionNodes.some(conditionNode =>
          this.diagram.connectors.some(connector => connector.sourceID === conditionNode.id && connector.targetID === node.id)
        )
      );

      if (conditionNodes.length === 0) {
        this.showSnackbar('Add atleast one Condition node before adding an Evaluation node');
        return;
      }

      if (existingEvaluationNode) {

        const stepWithConditions = this.nodes.filter((node: any) =>
          node.id.startsWith('Step') &&
          this.diagram.connectors.some(connector =>
            connector.sourceID === node.id &&
            this.nodes.some(condition =>
              condition.id.startsWith('Condition') &&
              connector.targetID === condition.id
            ))
        );
        const nextStep: any = stepWithConditions.filter(obj1 => {
          return this.stepWithEval.findIndex((ele: any) => obj1.id === ele.id) === -1;
        });

        if (nextStep[0]) {
          this.selectedStepNode = nextStep[0];

        } else {
          this.showSnackbar('Each step can only have one Evaluation node');
          return;
        }
      }
      newNode = {
        id: `${step.type}-${this.nodes.length}`,
        shape: { type: 'HTML', content: this.getNodeHTML(step.title, step.icon, `${step.type}-${this.nodes.length}`).html },
        annotations: [{ content: step.title }],
        constraints: NodeConstraints.Default | NodeConstraints.Drag | NodeConstraints.AllowDrop
      };
      this.stepWithEval.push(this.selectedStepNode)
    } else if (step.type === 'Step' || step.type !== 'Condition' && step.type !== 'Action' && step.type !== 'Evaluation') {
      newNode = {
        id: `${step.type}-${this.diagram.nodes.length}`,
        shape: { type: 'HTML', content: this.getNodeHTML(step.title, step.icon, `${step.type}-${this.diagram.nodes.length}`).html },
        annotations: [{ content: step.title }],
        constraints: NodeConstraints.Default | NodeConstraints.Drag | NodeConstraints.AllowDrop
      };
      this.selectedStepNode = step.type === 'Step' ? newNode : '';
    }

    if (newNode) {
      this.nodes.push(newNode);
      this.diagram.add(newNode);
      this.diagram.dataBind();
      this.addConnectors(newNode, step.type);
      this.diagram.doLayout();
      this.diagram.updateViewPort();
      return newNode;
    }
  }

  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  addConnectors(newNode: NodeModel, type: string) {
    let parentId: any | undefined;
    const stepNodes = this.nodes.filter((ele: any) => ele.id.startsWith('Step'));
    switch (type) {
      case 'Entity':
        parentId = this.nodes[0].id;
        break;
      case 'Step':
        parentId = this.nodes.find(node => node.id.startsWith('Entity'))?.id;
        break;
      case 'Condition':
        parentId = this.selectedStepNode ? this.selectedStepNode.id : this.nodes.find(node => node.id.startsWith('Step'))?.id;
        break;
      case 'Action':
        // parentId = this.selectedStepNode ? this.selectedStepNode.id : this.nodes.find(node => node.id.startsWith('Step'))?.id;
        const step = this.selectedStepNode ? this.selectedStepNode : this.nodes.find(node => node.id.startsWith('Step'));

        if (step) {
          // Find all Condition nodes connected to the Step node
          const conditionNodes = this.nodes.filter(node =>
            node.id.startsWith('Condition') &&
            this.diagram.connectors.some(connector => connector.sourceID === step.id && connector.targetID === node.id)
          );
          // Check if there's an Evaluation node connected to any of these Condition nodes
          const evaluationNode = this.nodes.find(node =>
            node.id.startsWith('Evaluation') &&
            conditionNodes.some(conditionNode =>
              this.diagram.connectors.some(connector => connector.sourceID === conditionNode.id && connector.targetID === node.id)
            )
          );
          // If an Evaluation node exists, connect Action to it; otherwise, connect Action to Step node
          if (evaluationNode) {
            parentId = evaluationNode.id;
          } else {
            parentId = step.id;
          }
        }
        break;
      case 'Evaluation':
        const stepNode = this.selectedStepNode ? this.selectedStepNode : this.nodes.find(node => node.id.startsWith('Step'));
        if (stepNode) {
          // Find all Condition nodes connected to the Step node
          const conditionNodes = this.nodes.filter(node =>
            node.id.startsWith('Condition') &&
            this.diagram.connectors.some(connector => connector.sourceID === stepNode.id && connector.targetID === node.id)
          );

          // Create connectors from each Condition node to the Evaluation node
          conditionNodes.forEach(conditionNode => {
            const connector: ConnectorModel = {
              id: `connector-${conditionNode.id}-${newNode.id}`,
              sourceID: conditionNode.id,
              targetID: newNode.id,
              style: { strokeColor: 'black' },
              constraints: ConnectorConstraints.Default & ~ConnectorConstraints.Select,
              annotations: [{ constraints: AnnotationConstraints.ReadOnly }],
            };
            this.diagram.add(connector);
          });
        }
        return;
        break;
    }

    if (parentId) {
      const connector: ConnectorModel = {
        id: `connector-${newNode.id}`,
        sourceID: parentId,
        targetID: newNode.id,
        style: { strokeColor: 'black' },
        constraints: ConnectorConstraints.Default & ~ConnectorConstraints.Select,
        annotations: [{ constraints: AnnotationConstraints.ReadOnly }],
      };
      this.diagram.add(connector);
    }
    if (type === 'Condition') {
      const stepNode = this.selectedStepNode ? this.selectedStepNode : this.nodes.find(node => node.id.startsWith('Step'));
      if (stepNode) {

        const conditionNodes = this.nodes.filter(node =>
          node.id.startsWith('Condition') &&
          this.diagram.connectors.some(connector => connector.sourceID === stepNode?.id && connector.targetID === node.id)
        );

        const existingEvaluationNode = this.nodes.find(node =>
          node.id.startsWith('Evaluation') &&
          conditionNodes.some(conditionNode =>
            this.diagram.connectors.some(connector => connector.sourceID === conditionNode.id && connector.targetID === node.id)
          )
        );

        if (existingEvaluationNode) {
          const connector: ConnectorModel = {
            id: `connector-${newNode.id}-${existingEvaluationNode.id}`,
            sourceID: newNode.id,
            targetID: existingEvaluationNode.id,
            style: { strokeColor: 'black' },
            constraints: ConnectorConstraints.Default & ~ConnectorConstraints.Select,
            annotations: [{ constraints: AnnotationConstraints.ReadOnly }],
          };
          this.diagram.add(connector);
        }
      }
    }
  }

  onClick(event: any): void {
    if (event.actualObject) {
      if ((event.actualObject).constraints !== undefined && event.actualObject.propName == "nodes") {
        const clickedNodeId: any = event.actualObject?.wrapper.id;
        this.selectedNodeId = clickedNodeId;
        const nodeElement = document.querySelector(`[id="${clickedNodeId}"]`);
        if (event.element.element && event.element.element.id == "diagram") {
          const bounds = nodeElement.getBoundingClientRect();
          this.contextMenu.open(bounds.top, bounds.right);
        }
      }
      if ((event.actualObject).constraints !== undefined && event.actualObject.propName == "connectors") {
        const clickedNodeId: any = event.actualObject?.properties.sourceID;
        this.selectedNodeId = clickedNodeId;
        const nodeElement = document.querySelector(`[id="${clickedNodeId}"]`);

        if (nodeElement) {
          const bounds = nodeElement.getBoundingClientRect();
          this.contextMenu.open(bounds.top, bounds.right);
        }
      }
    }
  }

  menuClick(event: MenuEventArgs): void {
    switch (event.item.id) {
      case 'configure':
        this.onConfigure(this.selectedNodeId);
        break;
      case 'delete':
        this.onDelete(this.selectedNodeId);
        break;
      default:
        break;
    }
  }
  onConfigure(selectedNodeId: string): void {
    const nodeType = selectedNodeId?.split('-')[0];
    this.openModal(selectedNodeId, nodeType)

  }
  findStepByTypeAndId(type: string, id: string, workflowData: any): string | null {
    for (const step of workflowData?.steps || []) {
      switch (type) {
        case 'Condition':
          if (step?.conditions?.some((cond: any) => cond.id === id)) {
            return step.id;
          }
          break;
        case 'Action':
          if (step?.actions?.some((act: any) => act.id === id)) {
            return step.id;
          }
          break;
        case 'Evaluation':
          const allConnectors = this.diagram.connectors;

          for (let i = 0; i < allConnectors.length; i++) {
            const connector = allConnectors[i];

            if (connector.targetID === id) {
              const sourceNode = this.diagram.getNodeObject(connector.sourceID);
              if (sourceNode) {
                if (step?.conditions?.some((cond: any) => cond.id === sourceNode.id)) {
                  return step.id;
                }
              }
            }
          }
          break;
      }
    }
    return null;
  }

  openModal(nodeId: string, type: string): void {
    const testStep = this.findStepByTypeAndId(type, nodeId, this.workflowData);
    // const stepNodeId = this.selectedStepNode?.id ? this.selectedStepNode.id : this.workflowData?.steps.find(node => node.id.startsWith('Step'))?.id || '';
    // const stepNodeId = this.selectedStepNode?.id ? this.selectedStepNode.id : testStep;
    const stepNodeId = testStep ? testStep : this.selectedStepNode?.id ? this.selectedStepNode.id : '';
    this.currentDialogData = { id: nodeId, type: type, data: this.getNodeDataById(nodeId, type), workflowData: this.workflowData, stepId: stepNodeId };
    this.showModal = true;
  }
  getNodeDataById(nodeId: string, nodeType: string): any {
    if (nodeType.startsWith('Entity')) {
      // return this.workflowData.entity;
      // return { id: nodeId, ...this.workflowData.entity };
      return this.workflowData;
    } else if (nodeType.startsWith('Step') || nodeType.startsWith('Condition')) {
      for (let step of this.workflowData.steps) {
        if (step.id === nodeId) {
          return step;
        } else if (nodeType.startsWith('Condition')) {
          for (let condition of step.conditions) {
            if (condition.id === nodeId) {
              return condition;
            }
          }
        }
      }
    } else if (nodeType.startsWith('Evaluation')) {
      for (let step of this.workflowData.steps) {
        if ((step.id === this.selectedStepNode?.id ? this.selectedStepNode.id : this.workflowData?.steps.find(node => node.id.startsWith('Step'))?.id || '') && step.evaluation) {
          return { evaluation: step.evaluation };
        }
      }
    } else if (nodeType.startsWith('Action')) {
      // Handle Action nodes
      for (let step of this.workflowData.steps) {
        if (!Array.isArray(step.actions)) {
          step.actions = [];
        }
        for (let action of step.actions) {
          if (action.id === nodeId) {
            return action;
          }
        }
      }
    }
    return null;
  }
  updateNodeData(data: any): void {
    const stepNodeId = this.selectedStepNode?.id ? this.selectedStepNode.id : this.workflowData?.steps.find(node => node.id.startsWith('Step'))?.id || '';
    const { id, type, ...rest } = data;
    if (type.startsWith('Entity')) {
      // this.workflowData.entity = { id, ...rest };
      Object.assign(this.workflowData, rest);
    }
    else if (type.startsWith('Step')) {
      this.updateOrAddNode(this.workflowData.steps, id, rest, 'actions');
      this.updateOrAddNode(this.workflowData.steps, id, rest, 'conditions');
    } else if (type.startsWith('Condition')) {
      const step = this.workflowData.steps.find(step => step.id === stepNodeId);
      if (step) {
        const conditionIndex = step.conditions.findIndex((cond: any) => cond.id === id);
        if (conditionIndex !== -1) {
          step.conditions[conditionIndex] = { id, ...rest };
        } else {
          step.conditions.push({ id, ...rest });
        }
      }
    } else if (type.startsWith('Evaluation')) {
      const step = this.workflowData.steps.find(step => step.id === stepNodeId);
      if (step) {
        step.evaluationExpression = rest.evaluation;
      }
    } else if (type.startsWith('Action')) {
      const step = this.workflowData.steps.find(step => step.id === stepNodeId);
      if (step) {
        const actionIndex = step.actions.findIndex((act: any) => act.id === id);
        if (actionIndex !== -1) {
          step.actions[actionIndex] = { id, ...rest };
        } else {
          step.actions.push({ id, ...rest });
        }
      }
    }
  }
  updateOrAddNode(array: any[], nodeId: string, newData: any, childKey: string = null): void {
    const index = array.findIndex(node => node.id === nodeId);
    if (index !== -1) {
      if (childKey) {
        array[index] = { id: nodeId, [childKey]: [], ...newData };
      } else {
        array[index] = { id: nodeId, ...newData };
      }
    } else {
      if (childKey) {
        array.push({ id: nodeId, [childKey]: [], ...newData });
      } else {
        array.push({ id: nodeId, ...newData });
      }
    }
  }

  closeModal() {
    this.showModal = false;
    this.selectedNodeId = null;
  }


  onDelete(nodeId: string): void {
    if (this.diagram) {
      const nodeType: any = nodeId.split('-')[0];
      const canDelete = this.checkCanDelete(nodeType, nodeId);
      if (!canDelete) return;
      const nodeToDelete = this.diagram.nodes.find(node => node.id === nodeId);
      if (nodeToDelete) {
        this.diagram.remove(nodeToDelete);
      }
      this.nodes = this.nodes.filter((item: any) => item.id !== nodeId);

      if (nodeType === 'Step') {
        this.workflowData.steps = this.workflowData?.steps.filter((step: any) => step.id !== nodeId);
        const stepNodes = this.nodes.filter((ele: any) => ele.id.startsWith('Step'));
        this.selectedStepNode = stepNodes[stepNodes.length - 1];
      } else if (nodeType === 'Condition' || nodeType === 'Action' || nodeType === 'Evaluation') {
        this.workflowData?.steps?.forEach((step: any) => {
          step.conditions = step?.conditions?.filter((condition: any) => condition.id !== nodeId);
          step.actions = step?.actions?.filter((action: any) => action.id !== nodeId);
          if (step?.evaluation?.id === nodeId) {
            delete step.evaluation;
          }
        });
      }
      this.selectedNodeId = '';
    }
  }

  checkCanDelete(nodeType: string, nodeId: string): boolean {
    switch (nodeType) {
      case 'Entity':
        if (this.nodes.length <= 2) {
          return true; // Only allow deletion if there are more than 2 nodes
        }
        this.showSnackbar(`You cannot delete the entity until you have removed all associated step nodes.`);
        return false;

      case 'Step':
        const step = this.workflowData?.steps?.find((ele: any) => ele.id === nodeId);
        if (step) {
          if (step?.conditions?.length > 0 || step?.actions?.length > 0) {
            this.showSnackbar(`You cannot delete the step until you have removed all associated conditions or actions.`);
            return false;
          }

          const hasConnectedConditions = this.diagram.connectors.some(connector =>
            connector.sourceID === nodeId &&
            this.nodes.some(node => node.id.startsWith('Condition') && connector.targetID === node.id)
          );
          const hasConnectedActions = this.diagram.connectors.some(connector =>
            connector.sourceID === nodeId &&
            this.nodes.some(node => node.id.startsWith('Action') && connector.targetID === node.id)
          );

          if (hasConnectedConditions || hasConnectedActions) {
            this.showSnackbar(`You cannot delete the step until you have removed all connected conditions or actions.`);
            return false;
          }
        }
        return true;

      case 'Condition':
        const hasConnectedEvaluations = this.diagram.connectors.some(connector =>
          connector.sourceID === nodeId &&
          this.nodes.some(node => node.id.startsWith('Evaluation') && connector.targetID === node.id)
        );

        if (hasConnectedEvaluations) {
          this.showSnackbar(`You cannot delete the condition until you have removed the connected evaluation.`);
          return false;
        }
        return true;

      default:
        return true;
    }
  }
  onDragOver(event: any) {
    event.preventDefault();
  }
  onDrag(card: any) {
    this.currCard = card;
    this.isFromStepsMenu = true;
  }
  onDrop(event: any, card: string) {
    event.preventDefault();
    if (this.isFromStepsMenu) {
      const rec: any = this.cards.find((ele: any) => ele.id == this.currCard.id);
      // this.done.push(rec);
      this.currCard = null;
      this.addNode(rec);
      this.isFromStepsMenu = false;
    }
  }
  toggleActive(item: any) {
    item.active = !item.active;
    this.workflowService?.getWorkflowData(item.code).subscribe((res:any)=>{
      const payload = { ...res, active: item.active };
      this.updateWorkflow(payload);
    },(error) => {
      console.error("Error fetching workflow data:", error);
    })
  }

  createWorkflow() {
    this.isCreated = true;
    this.isEdited = false;
    this.isLoading = false;
    this.workflowData = {
      code: '',
      name: '',
      entity: '',
      preFilterConditions: null,
      steps: [],
      active: false,
      cronExpression: '',
      diagramState: null
    }
    this.workflowCodeControl.setValue('');
    this.workflowNameControl.setValue('');
    this.resetDiagram();
    if (this.isCreated) {
      this.initializeDiagram();
    }
  }
  toggleHome() {
    this.isCreated = false;
    this.isEdited = false;
    this.getWorkflowList();
    this.workflowCodeControl.setValue('');
    this.workflowNameControl.setValue('');
  }

  switchTab() {
    this.schedule = !this.schedule;
  }

  onTabChange() {
    this.generateCron();
  }

  generateCron() {
    switch (this.selectedTab) {
      case 'minutes':
        this.cronExpression = this.workflowService.minutesToCron(this.minutesModel.minutes);
        break;
      case 'hourly':
        this.cronExpression = this.workflowService.hourlyToCron(this.hourlyModel.hours);
        break;
      case 'daily':
        this.cronExpression = this.workflowService.dailyToCron(this.dailyModel.time, this.dailyModel.days);
        break;
      case 'weekly':
        this.cronExpression = this.workflowService.weeklyToCron(this.weeklyModel.time, this.weeklyModel.days);
        break;
      case 'monthly':
        this.cronExpression = this.workflowService.monthlyToCron(this.monthlyModel.time, this.monthlyModel.day, this.monthlyModel.months);
        break;
      case 'yearly':
        this.cronExpression = this.workflowService.yearlyToCron(this.yearlyModel.time, this.yearlyModel.day, this.yearlyModel.month);
        break;
      case 'advanced':
        this.cronExpression = this.advancedModel.expression;
        break;
    }
  }
  public documentBody = document.body;
  toggleWeekDay(day: string) {
    const index = this.weeklyModel.days.indexOf(day);
    if (index > -1) {
      this.weeklyModel.days.splice(index, 1);
    } else {
      this.weeklyModel.days.push(day);
    }
    this.generateCron();
  }

  isWeekDaySelected(day: string): boolean {
    return this.weeklyModel.days.includes(day);
  }


  saveWorkflow(isInternalSave = false) {
    if (this.workflowCodeControl.invalid || this.workflowNameControl.invalid) {
      this.workflowCodeControl.markAsTouched();
      this.workflowNameControl.markAsTouched();
      return;
    }
    if (this.workflowNameControl.valid) {
      const diagramState = this.diagram.saveDiagram();
      this.workflowData.name = this.workflowNameControl.value;
      this.workflowData.code = this.workflowCodeControl.value;
      this.workflowData.cronExpression = this.cronExpression;
      this.workflowData.diagramState = diagramState;
      const completeWorkflowData = this.workflowData;
      // const updatedWorkflow = this.workflowData;
      if (!this.isEdited) {
        this.workflowService.createWorkflow(completeWorkflowData).subscribe(response => {
          this.showSnackbar('Workflow created successfully!');
          console.log('Response from save API:', response);
          this.isCreated = false;
          this.closeConfirmDialog();
          this.getWorkflowList();
        });
      }
      if (this.isEdited) {
        this.updateWorkflow(completeWorkflowData, isInternalSave);
      }
    } else {
      this.showSnackbar('Save cancelled. Workflow name is required.');
    }
  }


  updateWorkflow(updatedData, isInternalSave = false) {
    this.workflowService.updateWorkflow(updatedData).subscribe(response => {
      console.log('Workflow updated:', response);
      if (!isInternalSave) {
        this.showSnackbar('Workflow updated successfully!');
        this.closeConfirmDialog();
        this.getWorkflowList();
        this.isCreated = false;
        this.isEdited = false;
      } else {
        this.isCreated = true;
        this.isEdited = true;
      }
    }, error => {
      console.error('Error updating workflow:', error);
    });
  }

  closeConfirmDialog() {
    if (this.schedule) {
      this.switchTab();
    } else {
      this.confirmationDialog?.hide();
    }
  }
  editWorkflow(id: string) {
    this.isCreated = true;
    this.isEdited = true;
    this.initializationComplete$.pipe(
      filter(isInitialized => isInitialized),
      take(1)
    ).subscribe(() => {
      if (this.isEdited) {
        this.getSavedWorkflow(id);
      }
    });
  }
  async recreateDiagramFromData(data: any) {
    await new Promise((resolve: any) => {
      this.initializeDiagram();
      if (this.diagram) {
        const entity = data?.entity;
        if (entity) {
          const newNode = this.addNode({ type: 'Entity', title: 'Entity', icon: this.icons['Entity'] });
          data?.steps?.forEach((step: any) => {
            const stepNode: any = this.addNode({ type: 'Step', title: step.code, icon: this.icons['Step'] });
            step.id = stepNode?.id || null;

            step?.conditions?.forEach((condition: any) => {
              const conditionNode: any = this.addNode({ type: 'Condition', title: condition.code, icon: this.icons['Condition'] });
              condition.id = conditionNode?.id || null;
            });

            if (step.evaluationExpression) {
              const evaluationNode: any = this.addNode({ type: 'Evaluation', title: 'Evaluation', icon: this.icons['Evaluation'] });
            }

            step?.actions?.forEach((action: any) => {
              const actionNode: any = this.addNode({ type: 'Action', title: action.code || 'Action', icon: this.icons['Action'] });
              action.id = actionNode?.id || null;
            });
          });
        }
      }

      this.diagram.doLayout();
      resolve();
    });
    const diagramState = this.diagram.saveDiagram();
    this.workflowData.diagramState = diagramState;
    this.isLoading = false;
    this.saveWorkflow(true);
  }
  getSavedWorkflow(workflowId: string) {
    this.resetDiagram();
    // this.nodes = [];
    this.isEdited = true;
    this.isLoading = true;
    this.workflowService?.getWorkflowData(workflowId).subscribe((data: any) => {
      console.log('Retrieved workflow data:Saved', data);
      if (data && this.diagram) {
        this.workflowNameControl.setValue(data.name);
        this.workflowCodeControl.setValue(data.code);
        this.cronExpression = data.cronExpression;
        this.workflowData = data;
        if (data.diagramState) {
          this.diagram?.loadDiagram(data.diagramState);
          this.nodes = [...this.diagram.nodes];
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        } else {
          this.diagram.clear();
          this.recreateDiagramFromData(data);
        }
      }
    });
  }

  getWorkflowList() {
    this.workflowService.getAllWorkflows().subscribe((data: any) => {
      console.log('All saved workflows:', data);
      this.workflowList = data;
      this.resetDiagram();
    });
  }
  deleteWorkflow(id: string) {
    this.workflowService.deleteWorkflowData(id).subscribe(response => {
      if (response === 'Success') {
        this.showSnackbar('Workflow deleted successfully!');
        this.getWorkflowList();
        console.log('Workflow deleted successfully', this.workflowList);
      } else {
        console.log('Error deleting workflow:', response);
      }
    });
  }
  resetDiagram() {
    if (this.diagram) {
      this.diagram.clear();
      this.diagram.nodes = [];
      this.diagram.connectors = [];
      this.diagram.dataBind();
      this.nodes = [];
    }
  }

  EditWorkflowName() {
    this.isEditingName = true;
    setTimeout(() => {
      this.workflowNameInput.nativeElement.focus();
    }, 0);
  }
  onTouchEnd(event: TouchEvent, card: any) {
    this.onDrop(event, card);
  }
}

