<ng-container>
  <mat-toolbar class="d-flex justify-content-between buttons-align mat-toolbar-single-row">
    <div [formGroup]="formGroup">
      <div class="d-flex name-section">
        <input matInput #inputField formControlName="name" [readonly]="!isEditable" placeholder="Enter a name">
        <mat-icon matTooltip="Edit" class="opacity-50" (click)="focusNameElement()">edit</mat-icon>
      </div>
      <mat-error class="error-message" style="height: 26px;padding-left: 5px;font-size: small;"
        *ngIf="formGroup?.get('name').hasError('minlength')">Enter a valid name</mat-error>
    </div>
    <!-- Actions Containers -->
    <div class="actions-container">
      <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
        type="submit" (click)="save()" [disabled]="formGroup?.invalid || scenarioDate">
        <mat-icon matTooltip="Save"
          [ngClass]="{'icon-enabled': !formGroup.invalid && !scenarioDate, 'icon-disabled': formGroup.invalid || scenarioDate}">save</mat-icon>
      </button>
      <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
        type="button" (click)="delete($event , formData.code)" [disabled]="!formData || !formData.code || scenarioDate">
        <mat-icon matTooltip="Delete"
          [ngClass]="{'icon-enabled': formData && formData.code && !scenarioDate, 'icon-disabled': !formData || !formData.code || scenarioDate}">delete</mat-icon>
      </button>
      <button style="border: none;background-color: transparent;font-size: initial;outline: none;" type="submit"
        (click)="generateForecast();openPanel(1)" [disabled]="!formData || !formData.code || scenarioDate">
        <mat-icon matTooltip="Generate Forecast">cached</mat-icon>
      </button>
    </div>
  </mat-toolbar>



  <!-- Status Tracker Code -->


  <div class="forecast-body">

    <div class="forecast-summary">
      <div class="forecast-steps mat-elevation-z4">

        <div class="stepper-container">
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[0]}">
              <span *ngIf="forecastStepState[0]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[0]">1</span>
            </div>
            <div class="label" title="Define forecast parameters">DEFINE</div>
            <div class="line"></div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[1]}">
              <span *ngIf="forecastStepState[1]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[1]">2</span>
            </div>
            <div class="label" title="Run prediction">PREDICT</div>
            <div class="line"></div>
          </div>
          <div class="step">
            <div class="circle" [ngClass]="{'completed': forecastStepState[2]}">
              <span *ngIf="forecastStepState[2]" class="tick">&#10003;</span>
              <span *ngIf="!forecastStepState[2]">3</span>
            </div>
            <div class="label" title="Review & approve demand">REVIEW & APPROVE</div>
          </div>
        </div>
        <p>
          Forecasting for the upcoming season is streamlined using the FlowSmart methodology, which includes the
          following steps: Define, Predict, Review, and Approve.
        </p>
      </div>
      <div [formGroup]="formGroup" class="forecast-status mat-elevation-z4">
        <span class="field-class">
          <span class="name">Created on:</span>
          <span class="value">{{formData?.createdTime ? formatDate(formData.createdTime) : "-"}}</span>
        </span>
        <span class="field-class">
          <span class="name">Forecasted from:</span>
          <span class="value">{{formData?.forecastStartDate ? formatDate(formData.forecastStartDate) : "-"}}</span>
        </span>
        <span class="field-class">
          <span class="name">Demand applied on:</span>
          <span class="value">{{formData?.demandConversionDate ? formatDate(formData.demandConversionDate) :
            "-"}}</span>
        </span>
        <span class="actions"><mat-slide-toggle formControlName="active" (change)="approveForecast($event)">{{(formData
            != null && formData.active) ? "Approved Forecast" : "Approve this
            Forecast"}}</mat-slide-toggle></span>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel [expanded]="panelOpenState[0]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>DEFINE</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Finetune your forecast parameters</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngTemplateOutlet="defineForecastParamsTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="panelOpenState[1]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>PREDICT</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Let the machines work for you</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <ng-container *ngTemplateOutlet="predictSalesTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="panelOpenState[2]">
        <mat-expansion-panel-header>
          <mat-panel-title class="text">
            <strong>REVIEW & APPROVE</strong>
          </mat-panel-title>
          <mat-panel-description class="text">
            <strong>Consensus planning</strong>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div>
          <ng-container *ngTemplateOutlet="measureAndApproveTemplate"></ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ng-template #defineForecastParamsTemplate>
    <div class="define-parameters">
      <div class="actions-container" *ngIf="canPredict">
        <button color='primary' style="border: none;background-color: transparent;font-size: initial;outline: none;"
          type="submit" (click)="editScenario()" [disabled]="isEditable || !canPredict">
          <mat-icon matTooltip="Edit"
            [ngClass]="{'icon-enabled': !isEditable  && canPredict, 'icon-disabled': isEditable || !canPredict}">edit</mat-icon>
        </button>
        <button color="primary" style="border: none;background-color: transparent;font-size: initial;outline: none;"
          type="submit" (click)="save();openPanel(1);" [disabled]="!isEditable || !canPredict">
          <mat-icon matTooltip="Save"
            [ngClass]="{'icon-enabled': isEditable || canPredict, 'icon-disabled': !isEditable || !canPredict}">save</mat-icon>
        </button>
      </div>
      <div class="form cards-cont" [ngClass]="{'form-readonly' : !isEditable || !canPredict}" [formGroup]="formGroup">
        <div class="expansion-table-cont">
          <mat-accordion>
            <mat-expansion-panel hideToggle expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>Category Precisions:</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="table-container">
                <table mat-table class="full-width-table" [dataSource]="categoryPrevisionList.controls"
                  formArrayName="categoryPrevisionList">
                  <ng-container matColumnDef="category">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef> <strong>Category</strong>
                    </th>
                    <td mat-cell class="foo" *matCellDef="let group; let i = index"> {{ group.value.category }} </td>
                  </ng-container>

                  <ng-container matColumnDef="discountCutOff" *ngIf="isDiscount">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef><strong> Discount
                        cutoff</strong> </th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.discountCutOff" [readonly]="!isEditable" />
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="marginCutoff" *ngIf="!isDiscount">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef><strong> Margin
                        cutoff</strong> </th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.marginCutoff" [readonly]="!isEditable" />
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="growthRate">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef><strong> Growth Rate
                      </strong></th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.growthRate" [readonly]="!isEditable" />
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="returnRecoveryRate">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef><strong> Return Recovery
                        Rate </strong></th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.returnRecoveryRate" [readonly]="!isEditable" />
                    </td>
                  </ng-container>

                  <ng-container *ngIf="analyticsType === 'conversion_rate'" matColumnDef="conversionRate">
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>
                      <strong>Conversion Rate</strong>
                    </th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.conversionRate" [readonly]="!isEditable" />
                    </td>
                  </ng-container>

                  <ng-container *ngIf="analyticsType === 'revenue_per_listing'" matColumnDef="revenuePerListing">
                    <!-- Revenue per Listing Header -->
                    <th class="table_header table_cell foo" mat-header-cell *matHeaderCellDef>
                      <strong>Revenue per Listing</strong>
                    </th>
                    <td class="foo" mat-cell *matCellDef="let group; let i = index">
                      <input matInput [formControl]="group.controls.revenuePerListing" [readonly]="!isEditable" />
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="getHeaderColumns()">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns:getRowColumns();">
                  </tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="note-block"><mat-icon *ngIf="canApprove" class="tool-tip">info</mat-icon>Approved forecast
            scenarios can't be edited. If you wish to fine tune it then mark the scenario as not approved. </div>
        </div>
        <div class="properties-card-cont">
          <mat-accordion>
            <mat-expansion-panel hideToggle expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>Properties:</strong>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="display-form">
                <div class="toggle-container" [class.disabled]="!isEditable">
                  <div class="toggle-button" [ngClass]="{'active': salesTypeControl.value}"
                    (click)="salesTypeControl.setValue(true)">
                    Use Net Sales
                  </div>
                  <div class="toggle-button" [ngClass]="{'active': !salesTypeControl.value}"
                    (click)="salesTypeControl.setValue(false)">
                    Use Gross Sales
                  </div>
                </div>


                <mat-form-field class="duration-cont fs-margin-20">
                  <mat-label>Duration (in months)</mat-label>
                  <mat-select [formControl]="duration">
                    <mat-option [value]="duration"
                      *ngFor="let duration of [1,2,3,4,5,6,7,8,9,10,11,12]">{{duration}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="isDiscount">
                  <div class="mat-form-field">
                    <div class="d-flex justify-content-between align-items-center">
                      <mat-label>Discount cutoff</mat-label>
                      <input matInput class="label-input" [readonly]="true" value="{{overAllDiscountCutOff.value}} %">
                    </div>
                    <mat-slider thumbLabel thumbLabelVisibility="always" [displayWith]="formatLabel" tickInterval="1"
                      step="1" min="0" [formControl]="overAllDiscountCutOff" max="100" aria-label="units"
                      [ngStyle]="{'pointer-events': isEditable ? '' : 'none'}">
                    </mat-slider>
                  </div>
                </div>

                <div *ngIf="!isDiscount">
                  <div class="mat-form-field">
                    <div class="d-flex justify-content-between align-items-center">
                      <mat-label>Margin cutoff</mat-label>
                      <input matInput class="label-input" [readonly]="true" value="{{overAllMarginCutoff.value}} %">
                    </div>
                    <mat-slider thumbLabel thumbLabelVisibility="always" [displayWith]="formatLabel" tickInterval="1"
                      step="1" min="0" [formControl]="overAllMarginCutoff" max="100" aria-label="units"
                      [ngStyle]="{'pointer-events': isEditable ? '' : 'none'}">
                    </mat-slider>
                  </div>
                </div>

                <div class="mat-form-field">
                  <div class="d-flex justify-content-between align-items-center">
                    <mat-label>Growth Rate</mat-label>
                    <input matInput class="label-input" [readonly]="true" value="{{overAllGrowthRate.value}} %">
                  </div>
                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="1000" step="5" min="0"
                    [formControl]="overAllGrowthRate" max="500" aria-label="units"
                    [ngStyle]="{'pointer-events': isEditable ? '' : 'none'}"></mat-slider>
                </div>

                <div class="mat-form-field">
                  <div class="d-flex justify-content-between align-items-center">
                    <mat-label>Return Recovery Rate</mat-label>
                    <input matInput class="label-input" [readonly]="true" value="{{overAllRecoveryRate.value}} %">
                  </div>

                  <mat-slider thumbLabel [displayWith]="formatLabel" tickInterval="1000" step="5" min="0"
                    [formControl]="overAllRecoveryRate" max="100" aria-label="units"
                    [ngStyle]="{'pointer-events': isEditable ? '' : 'none'}"></mat-slider>
                </div>
                <div class="mat-form-field">
                  <div class="d-flex flex-column" style="gap: 10px;">
                    <ng-container *ngIf="analyticsType">
                      <ng-container *ngIf="analyticsType === 'conversion_rate'; else revenuePerListing">
                        <mat-label>Conversion Rate</mat-label>
                        <input matInput type="text" class="label-input"
                          style="width: 100%; text-align: left;padding: 2px;" placeholder="Enter Value"
                          value="{{overAllConversionRate.value}}" [formControl]="overAllConversionRate">
                      </ng-container>

                      <!-- Template for Revenue per Listing -->
                      <ng-template #revenuePerListing>
                        <mat-label>Revenue per Listing</mat-label>
                        <input matInput type="text" class="label-input"
                          style="width: 100%; text-align: left;padding: 2px;" placeholder="Enter Value"
                          value="{{overAllRevenuePerListing.value}}" [formControl]="overAllRevenuePerListing">
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #predictSalesTemplate>
    <div class="predict-sales">
      <div class="actions">
        <button mat-button class="mat-button mat-primary mat-raised-button" type="submit" (click)="generateForecast()"
          [disabled]="!canPredict">
          <mat-icon matTooltip="Generate Forecast">cached</mat-icon> {{(formData && (formData.status === 'Completed' ||
          formData.status === 'Error') && canApprove) ? "Re-compute Forecast" : "Generate Forecast"}}
        </button>
      </div>
      <div [formGroup]="formGroup" class="status-cont d-flex justify-content-between" style="margin: 0 10px;">
        <div class="stepper-container">
          <div *ngFor="let step of statuses; let i = index" class="step"
            [ngClass]="{'completed': (i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)),'error': (i == 99 && i == statuses.length - 1)}">
            <div *ngIf="i < statuses.length" class="circle"
              [ngClass]="{'completed': (i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)),  'error': (i == 99 && i == statuses.length - 1)}">
              <span *ngIf="i === predictStepIndex && i != statuses.length - 1" class="spinner"></span>
              <span *ngIf="i < predictStepIndex || (predictStepIndex == i && i == statuses.length - 1)"
                class="tick">&#10003;</span>
              <span *ngIf="i > predictStepIndex">{{ i + 1
                }}</span>
            </div>
            <div *ngIf="i < statuses.length" class="label" [title]="step">{{ step }}</div>
            <div *ngIf="i < statuses.length - 1" class="line" [ngClass]="{'completed': i < predictStepIndex}">
            </div>
          </div>
        </div>
      </div>
    </div>


  </ng-template>
  <ng-template #measureAndApproveTemplate>
    <ng-container *ngIf="!formData || !canApprove">
      <p style="font-style: italic;text-align: center;">Demand forecast not available. Check if you have completed
        Define & Predict steps.</p>
    </ng-container>
    <ng-container *ngIf=" canApprove">
      <div class="measure-approve" [formGroup]="formGroup">
        <div *ngIf="displayProjectionResult" class="actions">
          <div *ngIf="scenarioDate" class="message"> Demand Conversion happend on <strong> {{ formatDate(scenarioDate)
              }}</strong>
          </div>
          <div class='table-header'>
            <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="uploadFile($event)" />
            <button mat-button class="mat-button mat-secondary mat-raised-button" (click)="fileInput.click()"
              [disabled]="!canApprove">
              <mat-icon matTooltip="Upload Projection">file_upload</mat-icon> Upload Demand File
            </button>
          </div>
          <button mat-button class="mat-button mat-primary mat-raised-button" type="submit"
            (click)="this.active.setValue(true);save()" [disabled]="!canApprove">
            <mat-icon matTooltip="Approve Demand">verified</mat-icon> Approve Demand
          </button>
        </div>
        <div style="margin-bottom: 30px;">
          <!-- <canvas id="myChart" baseChart 
                  [data]="lineChartData" 
                  [labels]="lineChartLabels" 
                  [options]="lineChartOptions" 
                  [legend]="lineChartLegend" 
                  [type]="lineChartType">
          </canvas> -->
          <div class="graphFilter">
            <label for="filters" (click)="toggleFilterOptions()"><B>Demand forecast summary for ...</B></label>
            <div id="filterContainer">
              <span class="filter-option" (click)="toggleFilterOptions()"><B>{{ selectedFilter || 'All Category'
                  }}</B></span>
              <div class="filters-option" *ngIf="showFilterOptions">
                <span class="filter-option" (click)="onFilterChange('')">All Category</span>
                <span *ngFor="let value of categoryLevel1Values" class="filter-option" (click)="onFilterChange(value)">
                  {{ value }}
                </span>
              </div>
            </div>

          </div>
          <canvas id="myChart"> </canvas>
        </div>
        <app-syncfusion-grid htmlLegend="true" class="fs-padding-1"
          *ngIf="gridData && dynamicHeaders && !isCreateNewScenario" [dynamic_headers]="dynamicHeaders"
          [data]="gridData" [reportName]="formData.name" (dataEmitForPOPUP)="handlePOPUP($event)"></app-syncfusion-grid>
      </div>
    </ng-container>
    <div *ngIf="!displayProjectionResult" style="display:flex;justify-content: space-between;">
      <ng-container *ngFor="let number of [0, 1, 2,4,5]">
        <div style="width: 20%;margin-top:20px">
          <p style="
          font-style: normal;
          text-align: center;
          width: 80%;
          height: 10em;
          margin: 0px auto;
        " class="flowsmart-placeholder"></p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>