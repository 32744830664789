import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forecastHeader } from 'src/app/properties/forecasting_header';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ForecastingService } from 'src/app/services/forecasting.service';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ChartDataset, ChartOptions, Chart } from 'chart.js';
import { FilterBayService } from 'src/app/services/filter-bay.service';
import { AppSettingService } from 'src/app/services/app-setting.service';
// import { Color, Label } from 'ng2-charts';


@Component({
  selector: 'app-forecasting-form-molecule',
  templateUrl: './forecasting-form-molecule.component.html',
  styleUrls: ['./forecasting-form-molecule.component.scss'],
  providers: [DatePipe]
})
export class ForecastingFormMoleculeComponent implements OnInit, OnDestroy {
  @Input() formData;
  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;
  @Input() categoryL1;
  @Input() scenarioDate: string;
  @Output() scenarioUpdated = new EventEmitter<string>();
  @Output() dataEmitForPOPUPOUTER = new EventEmitter<{}>();
  gridData: any
  dynamicHeaders: any;
  formGroup: FormGroup;
  isEditable: boolean = false;
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  active: FormControl = new FormControl('');
  overAllDiscountCutOff: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  overAllMarginCutoff: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  overAllGrowthRate: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  overAllRecoveryRate: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  overAllConversionRate: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  overAllRevenuePerListing: FormControl = new FormControl(0, [Validators.required, Validators.minLength(1)]);
  duration: FormControl = new FormControl({ value: 3, disabled: !this.isEditable }, [Validators.required, Validators.minLength(1)]);
  salesTypeControl: FormControl = new FormControl({ value: false, disabled: !this.isEditable });
  categoryPrevisionList: FormArray;
  userAddedCat: boolean = false;
  displayProjectionResult: boolean = true;
  timeoutId: any;
  intervalId: any;
  isCreateNewScenario: boolean
  statuses: string[] = [
    "Data Cleansing",
    "Feature Engineering",
    "Model Evaluation",
    "Running Predictions",
    "Completed",
  ];
  isEditing: boolean = false;
  private subscription: Subscription;
  showLastStep: boolean = true;
  isExpanded: boolean = true;
  panelOpenState = [true, false, false];
  // Could be in the possible states NEW|DEFINED|PREDICTED|APPROVED|DEMAND_APPLIED
  canPredict: boolean = false;
  canApprove: boolean = false;
  forecastStepState: boolean[] = [false, false, false];
  predictStepIndex: number = -1;

  public lineChartData: ChartDataset<'line'>[] = [];
  public lineChartLabels: string[] = [];

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true
      }
    }
  };
  public lineChartLegend = true;
  public lineChartType = 'line';
  categoryLevel1Values: string[] = [];
  selectedFilter: string = '';
  chart: any;
  showFilterOptions = false;
  isDiscount: boolean = true;
  analyticsType: any
  forecastSetting: any;

  constructor(private fb: FormBuilder, private forecastingService: ForecastingService, private filterBayService: FilterBayService,
    private snack: MatSnackBar, private authService: AuthenticationService, private datePipe: DatePipe, private settingService: AppSettingService) { }

  ngOnInit(): void {
    this.forecastSetting = this.getForecastSetting();
    this.isDiscount = (this.forecastSetting === 'margin') ? false : true;
    this.analyticsType = this.getAnalyticsType();
    if (this.formData) {
      this.name.setValue(this.formData?.name || '');
      this.active.setValue((this.formData?.active || this.scenarioDate) || false);
      this.overAllDiscountCutOff.setValue(this.formData?.overAllDiscountCutOff || 0);
      this.overAllMarginCutoff.setValue(this.formData?.overAllMarginCutoff || 0);
      this.overAllGrowthRate.setValue(this.formData?.overAllGrowthRate || 0);
      this.overAllRecoveryRate.setValue(this.formData?.overAllRecoveryRate || 0);
      this.overAllConversionRate.setValue(this.formData?.overAllConversionRate || 0);
      this.overAllRevenuePerListing.setValue(this.formData?.overAllRevenuePerListing || 0);
      this.salesTypeControl.setValue(this.formData?.netSales || false);
      this.duration.setValue(this.formData?.duration || 3);
      this.forecastingService.getForecastStatus(this.formData?.code).subscribe(response => {
        let currentStatus = response['currentStatus'];
        this.predictStepIndex = (currentStatus != 'Error' ? this.statuses.indexOf(currentStatus) : 99);
        if (currentStatus === 'Completed' || currentStatus === 'Error') {
          this.loadProjectionResult()
        }
      });
    }
    const formBuilder = [];
    const L1Cat = this.categoryL1?.map(item => {
      const objItem = {
        category: item,
        discountCutOff: 0,
        marginCutoff: 0,
        growthRate: 0,
        returnRecoveryRate: 0,
        conversionRate: 0,
        revenuePerListing: 0
      };
      return objItem;
    });

    L1Cat?.map(ifNotAvail => {
      var checkCurrentVal;
      this.formData && this.formData['categoryPrevisionList']?.map(item => {
        if (ifNotAvail.category === item.category) {
          checkCurrentVal = item.category;
          const formGroup = this.fb.group({
            category: [item.category, Validators.required],
            discountCutOff: [item.discountCutOff || 0],
            marginCutoff: [item.marginCutoff || 0],
            growthRate: [item.growthRate || 0],
            returnRecoveryRate: [item.returnRecoveryRate || 0],
            conversionRate: [item.conversionRate || 0],
            revenuePerListing: [item.revenuePerListing || 0],
          });
          formBuilder.push(formGroup);
        }
      });
      if (ifNotAvail.category !== checkCurrentVal) {
        const formGroup = this.fb.group({
          category: [ifNotAvail.category],
          discountCutOff: [ifNotAvail.discountCutOff || ''],
          marginCutoff: [ifNotAvail.marginCutoff || ''],
          growthRate: [ifNotAvail.growthRate || ''],
          returnRecoveryRate: [ifNotAvail.returnRecoveryRate || ''],
          conversionRate: [ifNotAvail.conversionRate || ''],
          revenuePerListing: [ifNotAvail.revenuePerListing || ''],
        });
        formBuilder.push(formGroup);
      }
    });
    this.categoryPrevisionList = new FormArray(formBuilder);
    this.formGroup = new FormGroup({
      name: this.name,
      active: this.active,
      categoryPrevisionList: this.categoryPrevisionList,
      overAllDiscountCutOff: this.overAllDiscountCutOff,
      overAllMarginCutoff: this.overAllMarginCutoff,
      overAllGrowthRate: this.overAllGrowthRate,
      overAllRecoveryRate: this.overAllRecoveryRate,
      overAllConversionRate: this.overAllConversionRate,
      overAllRevenuePerListing: this.overAllRevenuePerListing,
      netSales: this.salesTypeControl,
      duration: this.duration
    });
    if (!this.formData) {
      this.createNewScenario();
    }
    this.computeFormState(this.formData);
    if (this.formData && this.formData.status && this.formData.status == "Completed" && !this.formData.active) {
      this.openPanel(2);
    }
    // this.loadLineGraphData(this.formData.code);

    // this.lineChartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
    // this.lineChartData = [
    //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Test Data', borderColor: 'blue', backgroundColor: 'rgba(0, 123, 255, 0.5)' }
    // ];
    // this.loadFilters();
    // this.loadLineGraphData(this.formData.code, ''); 
  }

  getHeaderColumns(): string[] {
    const analyticsColumn = this.analyticsType === 'conversion_rate' ? 'conversionRate' : (this.analyticsType === 'revenue_per_listing' ? 'revenuePerListing' : null);
    const forecastOperationColumn = this.forecastSetting === 'discount' ? 'discountCutOff' : 'marginCutoff';
    // return ['category', forecastOperationColumn, 'growthRate', 'returnRecoveryRate', analyticsColumn];
    return ['category', forecastOperationColumn, 'growthRate', 'returnRecoveryRate']
      .concat(analyticsColumn ? [analyticsColumn] : []);
  }
  getRowColumns(): string[] {
    return this.getHeaderColumns();
  }
  getForecastSetting(): string {
    const settings = this.settingService?.getSettings();
    return settings?.forecast?.operations_type || 'discount';
  }
  getAnalyticsType() {
    const settings = this.settingService?.getSettings();
    return settings?.forecast?.analytics_type || null;
  }
  loadFilters(): void {
    this.filterBayService.getFilters().subscribe((response: any) => {
      const categoryLevel1Filter = response.filterDataList.find(filter => filter.code === 'category_level1');
      if (categoryLevel1Filter) {
        this.categoryLevel1Values = categoryLevel1Filter.values;
      }
    });
  }
  toggleFilterOptions(): void {
    this.showFilterOptions = !this.showFilterOptions;
  }
  onFilterChange(value: string): void {
    this.selectedFilter = value;
    this.loadLineGraphData(this.formData.code, this.selectedFilter);
    this.showFilterOptions = false;
    // this.updateChart(this.selectedFilter);
  }

  loadLineGraphData(code: string, filter: string): void {
    this.forecastingService.getLineGraph(code, filter).subscribe((response: any) => {
      const ctx = document.getElementById('myChart') as HTMLCanvasElement;
      if (ctx) {
        const chartContext = ctx.getContext('2d');
        if (chartContext) {
          const chartData = {
            type: response.type,
            data: {
              labels: response.data.labels,
              datasets: response.data.datasets.map(dataset => ({
                ...dataset,
                borderColor: dataset.borderColor[0],
                backgroundColor: dataset.backgroundColor[0],
                pointBackgroundColor: dataset.pointBackgroundColor ? dataset.pointBackgroundColor[0] : null
              }))
            },
            options: {
              responsive: true,
              scales: {
                x: {
                  type: 'category',
                  labels: response.data.labels
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Quantity Sold'
                  }
                }
              }
            }
          };

          if (this.chart) {
            this.chart.destroy();
          }
          this.chart = new Chart(chartContext, chartData);
        }
      }
    }, error => {
      console.error('Error fetching line graph data:', error);
    });
  }
  createNewScenario() {
    this.name.setValue('');
    this.overAllDiscountCutOff.setValue(0);
    this.overAllMarginCutoff.setValue(0);
    this.overAllGrowthRate.setValue(0);
    this.overAllRecoveryRate.setValue(0);
    this.overAllConversionRate.setValue(0);
    this.overAllRevenuePerListing.setValue(0);
    this.salesTypeControl?.setValue(this.formData?.netSales || false);
    this.duration.setValue(3);
    this.categoryPrevisionList.controls.forEach(control => {
      control.get('discountCutOff').setValue('');
      control.get('marginCutoff').setValue('');
      control.get('growthRate').setValue('');
      control.get('conversionRate').setValue('');
      control.get('revenuePerListing').setValue('');
    });
    this.active.setValue(this.formData?.active || false);
    this.isCreateNewScenario = true;
    this.scenarioDate = null;
    this.editScenario();
  }
  focusNameElement() {
    this.inputField.nativeElement.focus();
  }
  ngAfterViewInit() {
    if (this.isCreateNewScenario) {
      this.inputField.nativeElement.focus();
    }
  }
  editScenario() {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      this.formGroup.get('duration').enable();
      this.salesTypeControl.enable();
    } else {
      this.formGroup.get('duration').disable();
      this.salesTypeControl.disable();
    }
  }
  handlePOPUP(event) {
    let header = event.header;
    let fieldData = event.fieldData;
    this.dataEmitForPOPUPOUTER.emit({ header, fieldData });
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    delete this.dynamicHeaders;
    delete this.gridData;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addFormGroup() {
    this.categoryPrevisionList.push(this.createFormGroup());
  }

  removeObjects(i) {
    this.categoryPrevisionList.removeAt(i);
  }

  openPanel(index: number) {
    this.panelOpenState = this.panelOpenState.map((_, i) => i === index);

  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      category: new FormControl('', Validators.required),
      discountCutOff: new FormControl('', [Validators.required, Validators.minLength(1)]),
      marginCutoff: new FormControl('', [Validators.required, Validators.minLength(1)]),
      growthRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      returnRecoveryRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      conversionRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      revenuePerListing: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }

  formatLabel(value: number): string {
    return `${value}`;
  }
  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MMMM d, yyyy');
  }

  delete(event: any, code: string) {

    if (this.formData && this.formData.code) {
      this.forecastingService.deleteForecastScenario(code).subscribe(
        () => {
          const msg = `Deleted Successfully`;
          this.snack.open(msg, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          this.scenarioUpdated.emit("");
        },
        (error) => {
          const msg = `Failed to delete scenario, please try again later`;
          this.snack.open(msg, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      );
    }

  }


  approveForecast(event: MatSlideToggleChange) {
    this.active.setValue(event.checked);
    this.save();
  }


  save() {
    let categoryPrevisionList: any
    // categoryPrevisionList = this.categoryPrevisionList.value.filter(item => (this.forecastSetting === 'discount' ? +item.discountCutOff > 0 : +item.marginCutoff > 0)
    //   || +item.growthRate > 0 || +item.returnRecoveryRate > 0 || (this.analyticsType === 'conversion_rate' ? +item.conversionRate > 0 : +item.revenuePerListing > 0));
    categoryPrevisionList = this.categoryPrevisionList.value.filter(item => {
      const forecastCondition = this.forecastSetting === 'discount'
        ? +item.discountCutOff > 0
        : +item.marginCutoff > 0;

      const growthCondition = +item.growthRate > 0;
      const returnRecoveryCondition = +item.returnRecoveryRate > 0;

      let analyticsCondition = false;
      if (this.analyticsType === 'conversion_rate') {
        analyticsCondition = +item.conversionRate > 0;
      } else if (this.analyticsType === 'revenue_per_listing') {
        analyticsCondition = +item.revenuePerListing > 0;
      }
      return forecastCondition || growthCondition || returnRecoveryCondition || analyticsCondition;
    });
    if (this.isCreateNewScenario) {
      this.forecastingService.createForecastScenario({ ...this.formGroup.value, categoryPrevisionList: categoryPrevisionList }).subscribe(res => {
        const msg = `Created Successfully`;
        this.scenarioUpdated.emit(res['code']);
        this.formData = res;
        this.isEditable = false;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.computeFormState(this.formData);
      }, (error) => {
        const msg = `Failed to create scenario, please try again later`;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      });
      this.isCreateNewScenario = false;
    } else {
      this.isCreateNewScenario = false;
      this.forecastingService.updateForecastScenario({ ...this.formData, ...{ ...this.formGroup.value, categoryPrevisionList: categoryPrevisionList } }).subscribe(res => {
        const msg = `Updated Successfully`;
        this.scenarioUpdated.emit(res['code']);
        this.computeFormState(res);
        this.isEditable = false;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      });
    }
  }

  computeFormState(formData) {
    if (formData == null) {
      //New Scenario
      this.canApprove = false;
      this.canPredict = false;
      this.formGroup.get('active').disable();
      this.forecastStepState = [false, false, false];
      return;
    }
    if (formData.demandConversionDate) {
      // Converted scenario should not be editable
      this.canApprove = false;
      this.canPredict = false;
      this.formGroup.get('active').disable();
      this.forecastStepState = [true, true, true];
      return;
    }

    if (!formData.active) {
      this.canPredict = true;
    } else {
      this.canPredict = false;
    }

    if ((formData.status && formData.status == "Completed")) {
      this.canApprove = true;
      this.formGroup.get('active').enable();
    } else {
      this.canApprove = false;
      this.formGroup.get('active').disable();
    }

    if (formData.status == "Error") {
      //Error in Predict step 
      this.forecastStepState = [true, false, false];
    } else if (formData.status == "Completed" && !formData.active) {
      //Awaiting approval 
      this.forecastStepState = [true, true, false];
    } else if (formData.active) {
      //All steps completed
      this.forecastStepState = [true, true, true];
    } else {
      this.forecastStepState = [true, false, false];
    }
  }

  uploadFile(event) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      // this.uploadName = event.target.files[0].name;
      this.forecastingService.uploadFile(event.target.files[0], this.authService.getUserName(), this.formData.code).subscribe((res) => {
        alert("File uploaded successfully...");
        // this.uploadName = 'upload File';
        // this.gridData = [];
        // this.dynamicHeaders = []
        // this.loadProjectionResult()
      }, (error) => {
        alert("Upload failed. Please try again");
        // this.uploadName = 'upload File';
      })
    }
  }
  generateForecast() {
    this.showLastStep = false;
    this.canPredict = false;
    this.forecastingService.generateForecast(this.formData.code).subscribe(response => {
      this.predictStepIndex = 0;
      this.gridData = [];
      this.pollStatus(10000);
    })
  }



  pollStatus(pollPeriod: number = 10000): void {
    this.subscription = interval(pollPeriod).pipe(
      switchMap(() => this.forecastingService.getForecastStatus(this.formData?.code)),
      takeWhile((response, index) => (response['currentStatus'] !== 'Completed' && response['currentStatus'] !== 'Error' && index < 10), true) // Include the last emitted value
    ).subscribe(response => {
      console.log(" Inside Poll status " + response['currentStatus']);
      let currentStatus = response['currentStatus'];
      this.predictStepIndex = (currentStatus != 'Error' ? this.statuses.indexOf(currentStatus) : 99);
      if (currentStatus === 'Completed' || currentStatus === 'Error') {
        this.openPanel(2);
        this.loadProjectionResult();
        this.canPredict = true;
        this.canApprove = true;
        this.forecastStepState = [true, true, false];
      }
    });
  }

  loadProjectionResult(): void {
    this.displayProjectionResult = false;
    this.forecastingService.getGridResults(this.formData?.code).subscribe(res => {
      const startDate = res['startMonth'];
      this.dynamicHeaders = JSON.parse(JSON.stringify(forecastHeader));
      const months = this.deriveMonths(startDate, res['duration'])
      let monthNum = 1;
      months.map((month) => {
        const dynamicField = {
          "field": `forecast_month_${monthNum++}`,
          "headerText": month,
          "width": "120",
        }
        this.dynamicHeaders.push(dynamicField);
      })
      this.gridData = res['items'];
      setTimeout(() => {
        this.displayProjectionResult = true;
      });
      // this.displayProjectionResult = true;
    })
    this.loadFilters();
    this.loadLineGraphData(this.formData.code, '');
  }

  deriveMonths(startMonth: string, duration: number): string[] {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const startDate = new Date(startMonth);
    const derivedMonths = [];
    for (let i = 0; i < duration; i++) {
      const monthIndex = (startDate.getMonth() + i) % 12;
      derivedMonths.push(months[monthIndex]);
    }
    return derivedMonths;
  }

}
